import React, { useEffect, useState } from "react"

import { navigate } from "gatsby"

import useAuthContext from "decentraland-gatsby/dist/context/Auth/useAuthContext"
import useFeatureFlagContext from "decentraland-gatsby/dist/context/FeatureFlag/useFeatureFlagContext"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import { Loader } from "decentraland-ui/dist/components/Loader/Loader"
import { Message } from "decentraland-ui/dist/components/Message/Message"

import Layout from "../../../components/Layout/Layout"
import NotFound from "../../../components/NotFound/NotFound"
import { useImageOptimization } from "../../../hooks/contentful"
import useEventQuery from "../../../hooks/useEventQuery"
import { FeatureFlag } from "../../../modules/ff"
import { redirectToAuthDapp } from "../../../modules/locations"
import Rewards from "../../../modules/rewards"

import "./claim.css"

const RewardsAPI = Rewards.get()
export default function EventQuiz(props: { event: string }) {
  const [ff, { loaded: featureFlagsLoaded, loading: featureFlagsLoading }] =
    useFeatureFlagContext()
  const event = useEventQuery(props.event)
  const [loadingClaim, setLoadingClaim] = useState(false)
  const [claimError, setClaimError] = useState("")
  const l = useFormatMessage()
  const [account, { loading }] = useAuthContext()
  const optimizedBanner = useImageOptimization(event?.claim_banner.url)

  useEffect(() => {
    if (event?.dispensers_keys.length && account) {
      setLoadingClaim(true)
      setClaimError("")
      Promise.all(
        event.dispensers_keys.map((dispenser) =>
          RewardsAPI.claimWearable({
            dispenserKey: dispenser,
            address: account,
          })
        )
      )
        .then((rewards) => {
          navigate(
            `/event/${[event.event_id]}/rewards/${rewards[0].data[0].id}`
          )
        })
        .catch((error) => {
          setClaimError(
            error?.body?.data?.messages.join(" ") ||
              l("page.claim_wearable.error_message")
          )
        })
        .finally(() => {
          setLoadingClaim(false)
        })
    }
  }, [event?.dispensers_keys, account])

  if (!featureFlagsLoaded || featureFlagsLoading || loading) {
    return <Loader active size="large" />
  }

  if (
    !ff.flags[FeatureFlag.ConferencePage] ||
    !event ||
    !event.dispensers_keys.length ||
    event.contentful_id !== new URL(window.location.href).searchParams.get("v")
  ) {
    return <NotFound />
  }

  if (!account) {
    redirectToAuthDapp()
    return null
  }

  return (
    <Layout className="event-claim">
      <img
        className="event-claim__banner"
        src={optimizedBanner.optimized}
        alt="Claim"
      />
      <div className="event-claim__container">
        <h2 className="event-claim__title">{l("page.claim_wearable.title")}</h2>
        {!claimError && (
          <>
            <h5 className="event-claim__subtitle">
              {l("page.claim_wearable.subtitle")}
            </h5>
            <Loader size="huge" active inline />
          </>
        )}
        {claimError && (
          <Message
            error
            visible
            className="event-claim__error"
            content={claimError}
            header={l("page.claim_wearable.error")}
          />
        )}
      </div>
    </Layout>
  )
}
